import React, { useState } from "react";
import { Button } from "../../../components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "../../../components/ui/dialog";

export interface ActionDropdownOptions {
  label: string;
  onClick?: () => void;
  icon?: React.ReactNode;
  value?: any;
  confirmAction?: boolean; // New optional prop (default: false)

}

interface ComponentProps {
  options: ActionDropdownOptions[];
  placeholder?: string;
  icon?: React.ReactNode;
  onClick?: (value: any) => void;
  className?: string;
  defaultValue?: string;
}

const ActionDropdown: React.FC<ComponentProps> = ({
                                                    options,
                                                    placeholder,
                                                    icon,
                                                    onClick,
                                                    className,
                                                  }) => {
  const [show, setShow] = useState(false);
  const [selectedOption, setSelectedOption] = useState<ActionDropdownOptions | null>(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleClick = (opt: ActionDropdownOptions) => {
    if (opt.confirmAction) {
      setSelectedOption(opt);
      setIsDialogOpen(true);
    } else {
      executeAction(opt);
    }
  };

  const executeAction = (opt: ActionDropdownOptions) => {
    if (onClick) onClick(opt);
    if (opt.onClick) opt.onClick();
    setShow(false); // Close dropdown
  };

  return (
      <div
          className={`relative cursor-pointer ${className}`}
          onMouseEnter={() => setShow(true)}
          onMouseLeave={() => setShow(false)}
      >
        <div className="flex items-center w-full p-2 border border-blue-900 rounded-lg">
          {icon && <div className="mr-2">{icon}</div>}
          {placeholder || "Action Dropdown"}
        </div>
        <div
            className={`absolute top-[42px] left-0 right-0 bg-white shadow-md shadow-gray-400 rounded-md grid overflow-hidden transition-all duration-[0.7s] ease-linear z-30 ${
                show ? "max-h-[600px]" : "max-h-0"
            }`}
        >
          {options.length > 0 &&
              options.map((opt, index) => (
                  <div
                      key={index}
                      className="flex items-center p-2 transition-all duration-200 hover:bg-gray-100 hover:text-blue-90"
                      onClick={() => handleClick(opt)}
                  >
                    {opt.icon && <div className="mr-2">{opt.icon}</div>}
                    {opt.label}
                  </div>
              ))}
        </div>

        {/* Confirmation Dialog */}
        <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
          <DialogContent>
            <DialogHeader>
              <DialogTitle>Are you sure?</DialogTitle>
            </DialogHeader>
            <DialogFooter>
              <Button variant="outline" onClick={() => setIsDialogOpen(false)}>
                Cancel
              </Button>
              <Button
                  onClick={() => {
                    if (selectedOption) executeAction(selectedOption);
                    setIsDialogOpen(false);
                  }}
              >
                Yes
              </Button>
            </DialogFooter>
          </DialogContent>
        </Dialog>
      </div>
  );
};

export default ActionDropdown;
