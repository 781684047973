import React from "react";
import {
    useGetEventOrderSummaryQuery,
} from "../../../../../services/order.api";

import { Card, CardContent, CardHeader, CardTitle } from "../../../../../components/ui/card"
import { Skeleton } from "../../../../../components/ui/skeleton"
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogTrigger } from "../../../../../components/ui/dialog"
import { motion } from "framer-motion"

interface OrderSummaryProps {
    event: any
}

const OrderSummary: React.FC<OrderSummaryProps> = ({ event }) => {
    const { data: orderSummary, isLoading } = useGetEventOrderSummaryQuery({
        id: event.id,
    })

    const formatCurrency = (amount: number) => {
        return new Intl.NumberFormat("en-PH", {
            style: "currency",
            currency: "PHP",
            minimumFractionDigits: 2,
        }).format(amount)
    }

    const summaryCards = [
        {
            title: "Total Quantity",
            value: orderSummary?.totalQuantity?.toLocaleString() || "8,455",
            description: "Total tickets ordered",
            details: {
                "shoppe": orderSummary?.totalPerExchange?.shoppee|| 0,
                "ticketnation": orderSummary?.totalPerExchange?.ticketnation || 0,
            },
        },
        {
            title: "Total Sales",
            value: formatCurrency(orderSummary?.total || 217298.0),
            description: "Total revenue from all tickets",
            details: {
                "Ticket Sales": formatCurrency(orderSummary?.subTotal || 0),
                "Order Fees": formatCurrency(orderSummary?.totalOrderFee || 0),
                "Ticket Fees": formatCurrency(orderSummary?.totalPerTicketFee  || 0),
            },
        },
    ]

    if (isLoading) {
        return (
            <div className="grid gap-4 md:grid-cols-2">
                {[1, 2].map((i) => (
                    <Card key={i} className="overflow-hidden">
                        <CardHeader className="space-y-0 pb-2">
                            <Skeleton className="h-4 w-1/2" />
                        </CardHeader>
                        <CardContent>
                            <Skeleton className="h-8 w-full" />
                            <Skeleton className="h-4 w-2/3 mt-2" />
                        </CardContent>
                    </Card>
                ))}
            </div>
        )
    }

    return (
        <div className="space-y-4">
            <h2 className="text-2xl font-bold tracking-tight">Order Summary</h2>
            <div className="grid gap-4 md:grid-cols-2">
                {summaryCards.map((card, index) => (
                    <Dialog key={index}>
                        <DialogTrigger asChild>
                            <Card className="overflow-hidden hover:shadow-lg transition-shadow cursor-pointer">
                                <motion.div whileHover={{ scale: 1.02 }} transition={{ type: "spring", stiffness: 300 }}>
                                    <CardHeader className="space-y-0 pb-2">
                                        <CardTitle className="text-sm font-medium text-muted-foreground">{card.title}</CardTitle>
                                    </CardHeader>
                                    <CardContent>
                                        <div className="text-2xl font-bold">{card.value}</div>
                                        <p className="text-xs text-muted-foreground mt-1">{card.description}</p>
                                    </CardContent>
                                </motion.div>
                            </Card>
                        </DialogTrigger>
                        <DialogContent>
                            <DialogHeader>
                                <DialogTitle>{card.title} Breakdown</DialogTitle>
                            </DialogHeader>
                            <div className="space-y-4">
                                {Object.entries(card.details).map(([key, value]) => (
                                    <div key={key} className="flex justify-between items-center border-b pb-2">
                                        <span className="text-sm text-muted-foreground">{key}</span>
                                        <span className="font-medium">{value}</span>
                                    </div>
                                ))}
                            </div>
                        </DialogContent>
                    </Dialog>
                ))}
            </div>
        </div>
    )
}

export default OrderSummary

