import dayjs from "dayjs";
import React from "react";
import { Copy, Download, Eye, Tool } from "react-feather";
import { FaMoneyBill } from "react-icons/fa";
import { HiFilter } from "react-icons/hi";
import { useMediaQuery } from "react-responsive";
import { useReactToPrint } from "react-to-print";
import { Button, PaginationContainer } from "../../../../components/common";
import ActionDropdown from "../../../../components/common/ActionDropdown";
import { Modal } from "../../../../components/common/Modals/Modal";
import { Event } from "../../../../models/event.model";
import { Order } from "../../../../models/order.model";
import {
  useDownloadOrderInvoicePDFMutation,
  useExportOrderByEventMutation,
  useGetOrdersByEventQuery,
  useUpdateOrderMutation,
  useReassignOrderTicketMutation, useRequestOrderRefundMutation
} from "../../../../services/order.api";
import DownloadHardTixModal from "../DownloadHardTixModal";
import ResendEmailModal from "../ResendEmailModal";
import { OrderRefundModal } from "./components/RefundModal";
import "./index.css";
import TicketsModal from "./TicketsModal";
import {FaTicket} from "react-icons/fa6";
import OrderSummary from "./components/OrderSummary";

interface ComponentProps {
  event: Event;
}
interface Filter {
  [key: string]: any;
}

const EventOrders: React.FC<ComponentProps> = ({ event }) => {
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1224px)",
  });
  const [filter, setFilter] = React.useState<Filter>({});
  const [openFilter, setOpenFilter] = React.useState<boolean>(false);
  const [page, setPage] = React.useState(1);
  const [skip, setSkip] = React.useState(0);
  const [take] = React.useState(10);
  // const [from, setFrom] = React.useState("");
  // const [to, setTo] = React.useState("");
  const [search, setSearch] = React.useState("");
  const [searchInput, setSearchInput] = React.useState("");
  const [selectedOrderId, setSelectedOrderId] = React.useState<string>("");
  const [open, setOpen] = React.useState<boolean>(false);
  const [openDownload, setOpenDownload] = React.useState<boolean>(false);
  const [ticketModal, setTicketModal] = React.useState<string>("");
  const [refundModal, setRefundModal] = React.useState<boolean>(false);
  const [resendEmailId, setResendEmailId] = React.useState<string>("");
  const [selectedItems, setSelectedItems] = React.useState<Order[]>([]);

  const handleCheckboxChange = (order: Order) => {
    if (selectedItems.some((item) => item.id === order.id)) {
      setSelectedItems(selectedItems.filter((item) => item.id !== order.id));
    } else {
      setSelectedItems([...selectedItems, order]);
    }
  };
  const componentRef = React.useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  const handlePageChange = (value: number) => {
    setPage(value);
  };

  React.useEffect(() => {
    setSkip((page - 1) * take);
  }, [page, take]);

  const [exportCsv, { isLoading: isExportLoading }] =
    useExportOrderByEventMutation();

  const [update, { isLoading: isUpdateLoading, isError, error, isSuccess }] =
    useUpdateOrderMutation();

  const [reassignTickets, {
    isLoading: isReassignLoading,
    isError: isReassignError,
    error: reassignError,
    isSuccess: isReassignSuccess
  }] = useReassignOrderTicketMutation();

  const handleExport = () => {
    exportCsv({
      id: event.id,
      eventName: event.name,
      ...filter,
      search,
    });
  };

  const { data, isLoading } = useGetOrdersByEventQuery({
    id: event.id,
    ...filter,
    search,
    skip,
    take,
  });

  const [requestRefund, {
    isLoading: isRefundLoading,
    isError: isRefundError,
    error: refundError,
    isSuccess: isRefundSuccess
  }] =
      useRequestOrderRefundMutation();



  if (isLoading  || isExportLoading || isReassignLoading)
    <h2>Loading...</h2>;

  const [
    downloadOrderInvoicePDF,
    { isLoading: downloadIsLoading, isSuccess: downloadIsSuccess },
  ] = useDownloadOrderInvoicePDFMutation();

  const getOptions = (
    id: string,
    orderNumber: number,
    exchange: string,
    isPrinted: boolean
  ) => {
    const optionsArr = [
      {
        label: "Copy ID",
        onClick: () => {
          navigator.clipboard.writeText(id);
        },
        icon: <Copy className="w-5 h-5" />,
      },
      {
        label: "Download",
        onClick: () => {
          downloadOrderInvoicePDF({
            orderId: id,
            orderNumber,
          });
        },
        icon: <Download className="w-5 h-5" />,
      },
      {
        label: "Resend",
        onClick: () => {
          setOpen(true);
          setResendEmailId(id);
        },
        icon: <Copy className="w-5 h-5" />,
      },
      {
        label: "Refund",
        onClick: () => {
          setRefundModal(true);
          setSelectedOrderId(id);
        },
        icon: <FaMoneyBill className="w-5 h-5" />,
      },
      {
        label: "Refund Request",
        onClick: () => {
          requestRefund({
            id: id,
            body:{}
          })
        },
        confirmAction: true,
        icon: <FaMoneyBill className="w-5 h-5" />,
      },

      {
        label: "ReAssign Ticket Request",
        onClick: () => {
            console.log("ReAssign Ticket Request");
          reassignTickets({
            id: id,
            body:{}
          })
        },
        icon: <FaTicket className="w-5 h-5" />,
        confirmAction: true,
      },
      {
        label: "View",
        onClick: () => {
          setTicketModal(id);
        },
        icon: <Eye className="w-5 h-5" />,
      },
    ];

    if (
      exchange === "shoppee" ||
      exchange === "tiktok" ||
      exchange === "lazada"
    ) {
      optionsArr.push({
        label: isPrinted ? "Printed" : "Not Printed",
        onClick: () => {
          update({
            id: id,
            isPrinted: !isPrinted,
          });
        },
        icon: <Copy className="w-5 h-5" />,
      });
    }

    return optionsArr;
  };

  return (
    <>
      <div className="p-1 border rounded shadow-sm lg:p-4" ref={componentRef}>
        <OrderSummary event={ event}  />
        <div className="flex items-center justify-between pb-4 mb-4 border-b">
          <h2 className="text-xl font-semibold">Event Orders</h2>
          <div className="flex gap-5">
            {/* <Button
              className="no-print"
              title="Print Event Orders"
              onClick={handlePrint}
            />{" "} */}

            <Button
              className="export-to-csv"
              variant="secondary"
              title="Export Order Details"
              onClick={handleExport}
            />
          </div>
        </div>
        {selectedItems.length > 0 && (
          <div>
            <div className="flex justify-between mb-1">
              <h2 className="font-semibold uppercase">
                Selected for Printing{" "}
                <span className="ml-2 text-green-500">{`(${selectedItems.reduce(
                  (prev, curr) => {
                    return (
                      prev +
                      curr.orderEventTickets.reduce((prev, curr) => {
                        return prev + curr.quantity;
                      }, 0)
                    );
                  },
                  0
                )})`}</span>
              </h2>
              <Button
                className=""
                title="Print Hard Tickets PDF"
                onClick={() => {
                  setOpenDownload(true);
                }}
              />
            </div>
            <table className="w-full mb-2 border rounded-md">
              <thead>
                <tr>
                  <th className="p-1 border-b border-r text-start">ID</th>
                  <th className="p-1 border-b border-r text-start">Quantity</th>
                  <th className="p-1 border-b text-start">Action</th>
                </tr>
              </thead>
              <tbody>
                {selectedItems.map((item) => {
                  return (
                    <tr>
                      <td className="p-1 border-b border-r">{item.id}</td>
                      <td className="p-1 border-b border-r">
                        {item.orderEventTickets.reduce((prev, curr) => {
                          return prev + curr.quantity;
                        }, 0)}
                      </td>
                      <td className="p-1 border-b">
                        <button
                          onClick={() =>
                            setSelectedItems(
                              selectedItems.filter((obj) => obj.id !== item.id)
                            )
                          }
                          className="px-2 py-1 text-white bg-red-400 rounded-md"
                        >
                          Remove
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        )}
        <div className="mb-2">
          <h3>Filters</h3>
          <div className="flex">
            <input
              className="flex-1 p-2 mr-2 border rounded-md"
              value={searchInput}
              placeholder="Search"
              onChange={(e) => {
                setSearchInput(e.target.value);
              }}
            />
            <Button title="Search" onClick={() => setSearch(searchInput)} />
          </div>
          {/* <div className="flex items-center mt-2">
            <label className="mr-2 font-semibold">Date from</label>
            <input
              className="p-2 mr-2 border rounded-md"
              type="date"
              placeholder="From"
              onChange={(e) => {
                setFrom(e.target.value + " 00:00:00");
              }}
            />
            <label className="mr-2 font-semibold">Date to</label>
            <input
              className="p-2 border rounded-md"
              type="date"
              placeholder="To"
              onChange={(e) => {
                setTo(e.target.value + " 23:59:59");
              }}
            />
          </div> */}
        </div>
        <div className="flex flex-col p-2 md:flex-row md:justify-between">
          <div className="flex flex-col gap-1 mb-2 lg:flex-row md:mb-0">
            {Object.values(filter).length > 0 &&
              Object.values(filter).map((value: any, index) => {
                return (
                  <div
                    className="flex justify-between px-2 py-1 text-white bg-blue-500 rounded-md"
                    key={index}
                  >
                    <div>
                      <span className="mr-1 font-semibold capitalize">
                        {Object.keys(filter)[index]}:
                      </span>
                      {`${value}`}
                    </div>
                    <button
                      className="w-6 h-6 ml-2 bg-red-400 rounded-full hover:cursor-pointer"
                      onClick={() => {
                        const tempFilter = filter;
                        delete tempFilter[Object.keys(filter)[index]];
                        setFilter({ ...tempFilter });
                      }}
                    >
                      X
                    </button>
                  </div>
                );
              })}
          </div>
          <button
            onClick={() => setOpenFilter(true)}
            className="flex items-center px-2 py-1 overflow-hidden text-white bg-blue-500 rounded-md"
          >
            <HiFilter /> <span className="ml-2">Filter</span>
          </button>
        </div>
        <div className="">
          {isDesktopOrLaptop && (
            <ul
              className="grid justify-end grid-cols-12 mb-4 text-lg font-semibold"
              // style={{ gridTemplateColumns: "repeat(13, minmax(0, 1fr))" }}
            >
              <li className="col-span-2">
                <span className="flex ml-8">Actions</span>
              </li>
              <li className="col-span-2">Email</li>
              <li className="col-span-1 col-print">Order Date</li>
              <div className="grid grid-cols-7 col-span-7 text-center grid-print">
                <li>Order Fee</li>
                <li>Ticket Type</li>
                <li>Quantity</li>
                <li className="col-span-2">Total Ticket Fee</li>
                <li>Sub-Total</li>
                <li>Total</li>
              </div>
            </ul>
          )}
          <div className="mb-2">
            <PaginationContainer
              totalCount={data?.count || 0}
              currentPage={page}
              pageSize={take}
              onPageChange={handlePageChange}
              label="Orders"
            >
              <>
                {data &&
                  data.data.map((order: Order, index) => (
                    <ul
                      key={index}
                      className={`grid p-2 lg:p-4 lg:mb-2 mb-1 ${
                        isDesktopOrLaptop
                          ? "items-center grid-cols-12  border rounded shadow-sm"
                          : "grid-cols-2 gap-1  border rounded shadow-sm"
                      } ${order.isRefunded ? "bg-red-200" : ""} ${order.status === "REFUND_REQUESTED" ? "bg-orange-200" : ""}`}
                    >
                      {!isDesktopOrLaptop && (
                        <>
                          <li>
                            <span>{index + 1}</span>
                          </li>
                          <li className="font-bold" />
                        </>
                      )}

                      {!isDesktopOrLaptop && (
                        <li className="flex items-center font-bold">Actions</li>
                      )}
                      <li className="lg:col-span-2">
                        <div className="flex items-center gap-8 text-xs">
                          {!order.isPrinted &&
                            (order.exchange === "shoppee" ||
                              order.exchange === "tiktok" ||
                              order.exchange === "lazada") && (
                              <input
                                type="checkbox"
                                checked={selectedItems.includes(order)}
                                onChange={() => handleCheckboxChange(order)}
                              />
                            )}
                          <li className="flex flex-col justify-end col-span-2 lg:col-span-1 md:flex-row no-print">
                            <div className="p-2 text-sm text-gray-700 break-words">
                              <ActionDropdown
                                placeholder="Actions"
                                icon={<Tool className="w-5 h-5" />}
                                options={getOptions(
                                  order.id,
                                  order.orderNumber,
                                  order.exchange,
                                  order.isPrinted
                                )}
                              />
                            </div>
                          </li>
                        </div>
                      </li>
                      {!isDesktopOrLaptop && (
                        <li className="flex items-center font-bold">Email</li>
                      )}
                      <li className="flex flex-wrap lg:pr-2 lg:col-span-2">
                        <p className="flex flex-col items-center break-all ...">
                          <span>{order.orderNumber}</span>
                          <span>{order.user.email}</span>
                          {order.isRefunded && (
                            <span className="font-semibold">(Refunded)</span>
                          )}
                        </p>
                      </li>
                      {!isDesktopOrLaptop && (
                        <li className="font-bold">Order Date</li>
                      )}
                      <li className="col-span-1 col-print">
                        {dayjs(order.createdAt).format("MM-DD-YY HH:mm A")}
                      </li>
                      {order.orderEventTickets.length > 0 && (
                        <div className="grid items-center grid-cols-2 col-span-2 lg:text-center lg:grid-cols-7 lg:col-span-7 grid-print">
                          {!isDesktopOrLaptop && (
                            <li className="font-bold">Order Fee</li>
                          )}
                          <li>{order.orderFee}.00</li>
                          {!isDesktopOrLaptop && (
                            <li className="font-bold">Ticket Type</li>
                          )}

                          <li>
                            {order.orderEventTickets
                              .map(
                                (odt) =>
                                  `${
                                    order.orderEventTickets.length > 1
                                      ? odt.tickets.length
                                      : ""
                                  } ${odt.eventTicket.section}`
                              )
                              .join(", ")}
                          </li>
                          {!isDesktopOrLaptop && (
                            <li className="font-bold">Quantity</li>
                          )}
                          <li>
                            {order.orderEventTickets
                              .map(
                                (orderEventTicks) =>
                                  orderEventTicks.tickets.length
                              )
                              .reduce((prev, curr) => prev + curr)}
                          </li>
                          {!isDesktopOrLaptop && (
                            <li className="font-bold">Ticket Fee</li>
                          )}
                          <li
                            className={`${isDesktopOrLaptop && "col-span-2"}`}
                          >
                            {order.totalPerTicketFee}
                          </li>
                          {!isDesktopOrLaptop && (
                            <li className="font-bold">Sub-Total</li>
                          )}
                          <li>{order?.subTotal}.00</li>
                          {!isDesktopOrLaptop && (
                            <li className="font-bold">Total</li>
                          )}
                          <li>{order?.total}.00</li>
                        </div>
                      )}
                      {ticketModal === order.id && (
                        <TicketsModal
                          isOpen={ticketModal === order.id}
                          setOpen={setTicketModal}
                          orderEventTickets={order.orderEventTickets}
                        />
                      )}
                    </ul>
                  ))}
                <ul
                  className={`grid p-2 lg:p-4 lg:mb-2 mb-1 ${
                    isDesktopOrLaptop
                      ? "items-center grid-cols-12  border rounded shadow-sm"
                      : "grid-cols-2 gap-1  border rounded shadow-sm"
                  }`}
                >
                  <li className="col-span-5 col-span-print" />
                  <div className="grid grid-cols-2 col-span-7 lg:text-center md:grid-cols-6 xl:grid-cols-7">
                    {!isDesktopOrLaptop && (
                      <li className="font-bold">Order Fee</li>
                    )}
                    <li className="">
                      {data?.data.reduce((prev, curr) => {
                        return prev + curr.orderFee;
                      }, 0)}
                      .00
                    </li>
                    {isDesktopOrLaptop ? (
                      <li className="col-span-1" />
                    ) : (
                      <li className="font-bold">Total Quantity</li>
                    )}
                    <li className="col-span-1">
                      {data?.data
                        .map((d) =>
                          d.orderEventTickets
                            .map(
                              (orderEventTicks) =>
                                orderEventTicks.tickets.length
                            )
                            .reduce((prev, curr) => prev + curr)
                        )
                        .reduce((partialSum, a) => partialSum + a, 0)}
                    </li>
                    {!isDesktopOrLaptop && (
                      <li className="font-bold">Total Ticket Fee</li>
                    )}
                    <li
                      className={` ${
                        isDesktopOrLaptop ? "col-span-2" : "col-span-1"
                      }`}
                    >
                      {data?.data.reduce(
                        (partialSum, a) => partialSum + a.totalPerTicketFee,
                        0
                      )}
                      .00
                    </li>
                    {!isDesktopOrLaptop && (
                      <li className="font-bold">Sub Total</li>
                    )}
                    <li className="col-span-1">
                      {data?.data
                        .map((d) => (d.subTotal ? d.subTotal : 0))
                        .reduce((partialSum, a) => partialSum + a, 0)}
                      .00
                    </li>
                    {!isDesktopOrLaptop && <li className="font-bold">Total</li>}
                    <li className="col-span-1">
                      {data?.data
                        .map((d) => (d.total ? d.total : 0))
                        .reduce((partialSum, a) => partialSum + a, 0)}
                      .00
                    </li>
                  </div>
                  <li />
                </ul>
              </>
            </PaginationContainer>
          </div>
        </div>
      </div>

      <OrderRefundModal
        open={refundModal}
        setOpen={setRefundModal}
        title="Refund Order"
        size="m"
        orderId={selectedOrderId}
        setOrderId={setSelectedOrderId}
      />


      <Modal open={open} setOpen={setOpen} title="Confirm Resend" size="xs">
        <ResendEmailModal
          setOpen={setOpen}
          resendEmailId={resendEmailId}
          setResendEmailId={setResendEmailId}
        />
      </Modal>
      <Modal
        open={openFilter}
        setOpen={setOpenFilter}
        title="Event Order Filter"
      >
        <Filter
          filter={filter}
          onClose={() => setOpen(false)}
          setFilter={setFilter}
        />
      </Modal>

      {openDownload && (
        <Modal
          open={openDownload}
          setOpen={setOpenDownload}
          title="Confirm Download"
          size="xs"
        >
          <DownloadHardTixModal
            setOpen={setOpenDownload}
            eventName={event.name}
            orderIds={selectedItems.map((item) => item.id)}
            setSelectedItems={setSelectedItems}
            eventId={event.id}
          />
        </Modal>
      )}
    </>
  );
};

export default EventOrders;

interface FilterProps {
  setFilter: React.Dispatch<React.SetStateAction<{}>>;
  onClose: () => void;
  filter: any;
}

const Filter: React.FC<FilterProps> = ({ setFilter, onClose, filter }) => {
  const [exchange, setExchange] = React.useState(filter.exchange || "");
  const [from, setFrom] = React.useState(filter.from || "");
  const [to, setTo] = React.useState(filter.to || "");
  const handleSubmit = () => {
    const filter: any = {};
    if (exchange) filter.exchange = exchange;
    if (from) filter.from = from;
    if (to) filter.to = to;
    setFilter(filter);
    onClose();
  };
  return (
    <div className="bg-gray-500">
      <table className="w-full">
        <tbody>
          <tr>
            <td className="flex p-2">
              <label className="mr-2 text-white">Exchange</label>
            </td>
            <td className="w-[99%] p-2">
              <select
                className="w-full px-3 py-2 rounded-md"
                value={exchange}
                onChange={(e) => setExchange(e.target.value)}
              >
                <option value="">All</option>
                <option value="ticketnation">Ticketnation</option>
                <option value="shoppee">Shopee</option>
                <option value="tiktok">Tiktok</option>
                <option value="Lazada">Lazada</option>
              </select>
            </td>
          </tr>
          <tr className="">
            <td className="flex p-2">
              <label className="mr-2 text-white">From</label>
            </td>
            <td className="w-[99%] p-2">
              <input
                value={from}
                onChange={(e) => setFrom(e.target.value)}
                className="w-full px-3 py-2 rounded-md"
                type="date"
                max={new Date().toISOString().split("T")[0]}
              />
            </td>
          </tr>
          <tr>
            <td className="flex p-2">
              <label className="mr-2 text-white">To</label>
            </td>
            <td className="w-[99%] p-2">
              <input
                value={to}
                onChange={(e) => setTo(e.target.value)}
                className="w-full px-3 py-2 rounded-md"
                type="date"
                max={new Date().toISOString().split("T")[0]}
              />
            </td>
          </tr>
        </tbody>
      </table>
      <button
        onClick={() => handleSubmit()}
        className="w-full col-span-2 px-3 py-2 text-white bg-blue-500 rounded-md"
      >
        Filter
      </button>
    </div>
  );
};
